import { Rights } from '@shared/enums/rights.enum';
import { PlanId } from '@shared/models/plan.model';
import { IdentityData } from '@shared/models/prefs.model';
import { TeamFeature } from '@shared/models/features.model';

/**
 * User account related data models.
 *
 * @unstable
 */

export interface UserDiscoveredQuestion {
  lastUsed: number;
}

export class UserData {
  $key?: string;
  team?: string;

  teams: { [teamKey: string]: Rights };
  surveys: { [teamKey: string]: { [surveyKey: string]: Rights } };
  identities?: { [teamKey: string]: IdentityData };
  invites?: InviteData[];
  discoveredQuestions?: Record<string, UserDiscoveredQuestion>;
  hideAdminTools?: boolean;
}

export interface EmailSenderData {
  address: string;
  domain: string;
  forwardTo: string;
  readonly: boolean;
}

export interface SmsSenderData {
  name: string;
  readonly: boolean;
}

export enum TeamType {
  Client = 'client',
  Parent = 'parent',
  Child = 'child',
}

export type SignupData = {
  parentTeam?: string;
  handle: string;
  imageUrl: string;
  welcomeText: string;
};

export type FeedbackSettings = {
  $key: string;
  enabled: boolean;
  shareLink: string;
  repeat: number;
  trigger: string;
};

export interface TeamParentSettings {
  onlyParentTemplates?: boolean;
  disableBlankTemplates?: boolean;
  gdprLinks?: Record<'en' | 'fi', string>;
  hideSurveyTutorial?: boolean;
  surveyTutorialEmbed?: string;
  disableUserInvite?: boolean;
  signup?: SignupData;
  feedback?: Record<string, FeedbackSettings>;
}

export interface TeamAdminSettings {
  plan?: PlanId;
  hubSpot?: {
    companyId?: string;
    dealId?: string;
  };
  auditLogging?: boolean;
  disableSms?: boolean;
  disableEmail?: boolean;
  disableAnalytics?: boolean;
  disableBetaFeatures?: boolean;
  disableChildAiStats?: boolean;
  sendInviteFromConsole?: boolean;
  createChildFromConsole?: boolean;
  useChildQuota?: boolean;
  childBetaFeatures?: boolean;
  autoEnableChildBetaFeatures?: boolean;

  extensions?: string[];
  features?: Partial<Record<TeamFeature, boolean>>;

  smsSender?: SmsSenderData;
  emailSender?: EmailSenderData;
  previousEmailSender?: Partial<EmailSenderData>;

  type?: TeamType;
  childOf?: string;
}

export class TeamData {
  $key: string;
  name: string;
  logo: string;
  color?: string;

  // read only in player
  plan?: PlanId;

  users: { [key: string]: Rights };
  invited: { [key: string]: number };

  domain: string;
  creator: string;
  created: number;

  city: string;
  country: string;
  postcode: string;

  website: string;

  facebook: string;
  linkedin: string;
  twitter: string;

  adminSettings?: TeamAdminSettings;
  parentSettings?: TeamParentSettings;
}

export interface InviteData {
  $key: string;
  email: string;
  providers?: string[];

  rights: number;

  status: number;
  time: string;

  teamKey: string;
  teamName: string;
  teamLogo: string;

  userKey: string;
  userName: string;
  accepted?: number;
  rejected?: number;
}
