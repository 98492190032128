import { TeamAdminSettings, TeamData, TeamParentSettings, TeamType } from '@shared/models/account.model';
import { Rights } from '@shared/enums/rights.enum';
import { BillingPlanData } from '@shared/models/billing.model';

import { teamLogoPlaceholder } from './assets.utilities';

export function parseDefaultAdminSettings(settings: Partial<TeamAdminSettings>): TeamAdminSettings {
  return {
    plan: 'free_plan',
    type: TeamType.Client,

    auditLogging: false,
    disableAnalytics: false,
    disableEmail: false,
    disableSms: false,
    sendInviteFromConsole: true,
    createChildFromConsole: true,
    disableChildAiStats: false,
    extensions: [],

    ...settings,
  };
}
export function parseDefaultParentSettings(settings: Partial<TeamParentSettings>): TeamParentSettings {
  return {
    onlyParentTemplates: false,
    disableBlankTemplates: false,
    ...settings,
  };
}

export function isParent(teamOrType: TeamData | TeamType): boolean {
  return isTeamType(teamOrType, TeamType.Parent);
}

export function isChild(teamOrType: TeamData | TeamType): boolean {
  return isTeamType(teamOrType, TeamType.Child);
}

export function isTeamType(teamOrType: TeamData | TeamType, isType: TeamType): boolean {
  const type = typeof teamOrType === 'string' ? teamOrType : teamOrType?.adminSettings?.type;

  return type === isType;
}

export function teamHasUsers(team: TeamData): boolean {
  return Rights.objectHasRights(team?.users, Rights.VIEW);
}

export function teamHasInvites(team: TeamData): boolean {
  return Rights.objectHasRights(team?.invited, Rights.VIEW);
}

export function teamHasOwner(team: TeamData): boolean {
  return Rights.objectHasRights(team?.users, Rights.OWNER);
}

export function teamHasInvitedOwner(team: TeamData): boolean {
  return Rights.objectHasRights(team?.invited, Rights.OWNER);
}

export function hasFreePlan(plan: BillingPlanData): boolean {
  return !plan || !plan.stripeId || plan.billingType === 'free' || plan.plan?.id === 'free_plan';
}

export function teamLogo(team: TeamData): string {
  return team?.logo || teamLogoPlaceholder(team?.created);
}

export function teamColor(team: TeamData): string {
  const defaultColor = '#EAEFF1';
  return team?.color || defaultColor;
}

export function teamName(team: TeamData): string {
  return team?.name || '';
}
