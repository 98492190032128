/**
 * Manages authentication data for the user.
 *
 * AuthManager methods should accept "firebase.User"
 * and return data from @shared/models/account.model
 *
 *
 * @unstable
 */

import { environment } from '@env/environment';

import firebase from 'firebase/compat/app';

import { BehaviorSubject, combineLatest, concat, Observable, of, Subject, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import { runOutsideZone } from '@shared/utilities/angular.utilities';
import { UserInfo } from '@shared/models/auth.model';
import {
  MonitorLoginExpiration,
  RemoveUserOnAuthTokenChange,
  ResetUserInfo,
  SaveClaimsOnAuthTokenChange,
  SaveTokenOnAuthTokenChange,
  SignOutWithRedirect,
  UpdateUserOnAuthTokenChange,
} from '@shared/states/auth.actions';
import { AuthDoneFetchAccountData } from '@shared/states/account.actions';
import { LocalStorage, SessionStorageService } from 'ngx-webstorage';
import { ServerError } from '@shared/states/error.actions';
import { InviteData, SignupData } from '@shared/models/account.model';
import { DatabaseWrapper } from '@shared/services/database-wrapper.service';
import { ZefApi } from '@shared/services/zef-api.service';
import { mapObjectKey } from '@shared/operators/map-object-key.operator';
import { PrefsState } from '@shared/states/prefs.state';
import { RouterState } from '@shared/states/router.state';
import { AuditLogService } from '@shared/services/audit-log.service';
import { AuthWrapper } from '@shared/services/auth-wrapper.service';
import { mapListKeys } from '@shared/operators/map-list-keys.operator';
import { Requests } from '@shared/enums/requests.enum';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { Commands } from '@shared/enums/commands.enum';
import { AuthState } from '@shared/states/auth.state';

type User = firebase.User;

type AuthCustomResponse = {
  token?: string;
  // Only used for private link
  share?: { surveyKey: string; reportKey: string; teamKey: string };
};

@Injectable({
  providedIn: 'root',
})
export class AuthManager {
  @LocalStorage('consent')
  trackingAllowed: boolean | undefined;
  signup$: BehaviorSubject<SignupData | null> = new BehaviorSubject(null);

  readonly idTokenChange: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  // TODO: Remove this and update Analytics to use different data
  public readonly onSignupComplete = new Subject<User>();

  constructor(
    private db: DatabaseWrapper,
    private auth: AuthWrapper,
    private zone: NgZone,
    private za: ZefApi,
    private store: Store,
    private al: AuditLogService,
    private ss: SessionStorageService,
    private http: HttpClient,
    private cf: CloudFunctions,
  ) {}

  private userInfo(user: User): UserInfo {
    return {
      $key: user.uid,
      uid: user.uid,
      email: user.email,
      photoURL: user.photoURL,
      phoneNumber: user.phoneNumber,
      isAnonymous: user.isAnonymous,
      displayName: user.displayName,
      providerData: user.providerData,
      emailVerified: user.emailVerified,
      creationTime: new Date(user.metadata.creationTime).getTime(),
      lastSignInTime: new Date(user.metadata.lastSignInTime).getTime(),
    };
  }

  private currentUser(): Observable<User> {
    return this.auth.user.pipe(take(1));
  }

  private currentAuth(): firebase.auth.Auth {
    return firebase.auth(firebase.app(environment.firebase.appId));
  }

  init(disableAnonymous?: boolean) {
    const init = this.auth.idTokenResult.pipe(
      withLatestFrom(this.auth.user, this.auth.getRedirectResult()),
      switchMap(([idToken, user, redirect]) => {
        const emailDomain = (user?.email || '').split('@')[1];

        const isORYLogin = idToken?.claims?.ory?.sign_in_provider?.startsWith('saml.');
        const isSSOLogin = idToken?.claims?.firebase?.sign_in_provider?.startsWith('saml.');
        const isOIDCLogin = idToken?.claims?.firebase?.sign_in_provider?.startsWith('oidc.');

        return (
          isORYLogin || isSSOLogin || isOIDCLogin || !emailDomain ? of(false) : this.checkSSODomain(emailDomain)
        ).pipe(
          switchMap((ssoLoginNeeded) => {
            if (idToken?.token === this.store.selectSnapshot(({ auth }) => auth?.authToken)) {
              return of(void 0);
            }

            console.log('Authenticated user:', user);
            console.log('Redirect result user:', redirect);
            console.log('SSO login information:', isORYLogin || isSSOLogin || isOIDCLogin, ssoLoginNeeded);

            if (redirect?.user && redirect?.additionalUserInfo?.isNewUser === true) {
              const info: UserInfo = this.userInfo(redirect.user);

              this.idTokenChange.next(idToken?.token);

              this.store.dispatch([new UpdateUserOnAuthTokenChange({ ...info, isNewUser: true })]);

              return of(void 0);
            } else if (user !== null) {
              const info: UserInfo = this.userInfo(user);

              const queryParams = this.store.selectSnapshot(RouterState.queryParams);

              const isLoginRoute = (location?.pathname || '').includes('/login/');
              const isPrivateRoute = (location?.pathname || '').includes('/private/');

              this.store.dispatch(new UpdateUserOnAuthTokenChange(info));

              this.store.dispatch([
                new SaveTokenOnAuthTokenChange(idToken?.token),
                new SaveClaimsOnAuthTokenChange(idToken?.claims),
              ]);

              if (!isPrivateRoute) {
                if (ssoLoginNeeded) {
                  this.store.dispatch(new SignOutWithRedirect(true));
                } else {
                  if (isORYLogin || isSSOLogin || isOIDCLogin) {
                    this.store.dispatch(new MonitorLoginExpiration(user.uid));
                  }

                  if (isSSOLogin) {
                    this.cf
                      .putOnce(Commands.CreateTeam)
                      .pipe(
                        catchError(() => {
                          sessionStorage.setItem('login-error', 'SSO post login error');

                          return of(null);
                        }),
                      )
                      .subscribe(() => {
                        if (!isLoginRoute) {
                          this.store.dispatch([new AuthDoneFetchAccountData(info?.$key)]);
                        } else {
                          this.store.dispatch([
                            new AuthDoneFetchAccountData(info?.$key),
                            new Navigate([`/surveys`], queryParams),
                          ]);
                        }
                      });
                  } else if (isOIDCLogin) {
                    this.al.logAuthAccess(of(user?.email), 'LOGIN');

                    if (!isLoginRoute) {
                      this.store.dispatch([new AuthDoneFetchAccountData(info?.$key)]);
                    } else {
                      this.store.dispatch([
                        new AuthDoneFetchAccountData(info?.$key),
                        new Navigate([`/surveys`], queryParams),
                      ]);
                    }
                  } else {
                    this.store.dispatch(new AuthDoneFetchAccountData(info?.$key));
                  }
                }
              }

              this.idTokenChange.next(idToken?.token);

              return of(void 0);
            } else if (!disableAnonymous) {
              this.store.dispatch(new RemoveUserOnAuthTokenChange());

              this.idTokenChange.next(null);

              return this.signInAnonymously();
            } else {
              return of(void 0);
            }
          }),
        );
      }),
    );

    init.subscribe();

    return init.pipe(take(1));
  }

  signOut() {
    sessionStorage.removeItem('login-state');

    const email = this.currentAuth().currentUser?.email;

    return concat(
      ...(email ? [this.al.sendAuthLog({ status: 'SUCCESS', auth: 'LOGOUT', email })] : []),
      this.runWithAuth((auth) => auth.signOut()),
    );
  }

  deleteUser() {
    return this.runWithUser((user) => user?.delete());
  }

  refreshToken() {
    return this.runWithUser((user) => user.getIdToken(true));
  }

  signInAnonymously() {
    console.log('Signing in anonymously');

    return this.runWithAuth((auth) => auth.signInAnonymously());
  }

  signInWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'consent' });

    return this.al.logAuthAccess(
      this.runWithAuth((auth) => auth.signInWithPopup(provider)).pipe(map((user) => user.user?.email)),
      'LOGIN',
    );
  }

  signInWithMicrosoft() {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    // provider.setCustomParameters({ prompt: 'consent' });

    return this.al.logAuthAccess(
      this.runWithAuth((auth) => auth.signInWithPopup(provider)).pipe(map((user) => user.user?.email)),
      'LOGIN',
    );
  }

  unlinkPassword() {
    return this.runWithUser((user) => user.unlink('password')).pipe(
      map((user) => this.userInfo(user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  resetPassword(code: string, password: string) {
    return this.runWithAuth((auth) => auth.confirmPasswordReset(code, password));
  }

  linkPassword(email: string, password: string) {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    return this.runWithUser((user) => user.linkWithCredential(credential)).pipe(
      map((cred) => this.userInfo(cred?.user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  unlinkProvider(provider: string) {
    return this.runWithUser((user) => user.unlink(provider)).pipe(
      map((user) => this.userInfo(user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  linkGoogle(login_hint?: string) {
    const provider = new firebase.auth.GoogleAuthProvider();

    if (login_hint) {
      provider.setCustomParameters({ login_hint, prompt: 'consent' });
    }

    return this.runWithUser((user) => user.linkWithPopup(provider)).pipe(
      map((cred) => this.userInfo(cred?.user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  linkMicrosoft(login_hint?: string) {
    const provider = new firebase.auth.OAuthProvider('microsoft.com');

    if (login_hint) {
      provider.setCustomParameters({ login_hint /* prompt: 'consent' */ });
    }

    return this.runWithUser((user) => user.linkWithPopup(provider)).pipe(
      map((cred) => this.userInfo(cred?.user)),
      tap((info) => this.store.dispatch(new UpdateUserOnAuthTokenChange(info))),
    );
  }

  /*
   * SSO authentication is performed in 3 steps, login states for the steps are:
   * 1. Redirected:    The user is redirected to the SSO login url
   * 2. "Finished":    Teams update done and user is redirected to the surveys page
   */
  ssoLogin(ssoDomain?: string) {
    let provider;

    this.getSSOSettings(ssoDomain).subscribe((settings) => {
      if (!settings) {
        this.store.dispatch(new SignOutWithRedirect(true));
      } else if (settings.useOIDC) {
        console.log('Performing oidc login');

        const queryParams = this.store.selectSnapshot(RouterState.queryParams);

        provider = new firebase.auth.OAuthProvider('oidc.' + (settings.providerId || 'sso'));

        provider.setCustomParameters({
          tenant: ssoDomain || 'test',
          organization: queryParams.zefOrg || '',
        });

        this.runWithAuth((auth) => auth.signInWithRedirect(provider))
          .pipe(
            catchError((error) => {
              console.error('SSO login failed', error);

              sessionStorage.setItem('login-error', 'SSO login failed');

              this.store.dispatch(new SignOutWithRedirect(true));

              return of(null);
            }),
          )
          .subscribe();
      } else {
        // TODO: This should be removed or transformed to above flow in the future
        console.log('Performing saml login');

        provider = new firebase.auth.SAMLAuthProvider(`saml.${ssoDomain || 'sso'}`);

        if (!sessionStorage.getItem('login-state')) {
          console.log('SSO login started', ssoDomain);

          sessionStorage.setItem('login-state', 'redirected');

          this.runWithAuth((auth) => auth.signInWithRedirect(provider))
            .pipe(
              catchError((error) => {
                console.error('SSO login failed', error);

                sessionStorage.removeItem('login-state'); // Reset the auth flow

                sessionStorage.setItem('login-error', 'SSO login failed');

                this.store.dispatch(new SignOutWithRedirect(true));

                return of(null);
              }),
            )
            .subscribe();
        } else {
          console.log('SSO login completed', ssoDomain, this.currentAuth().currentUser);

          sessionStorage.removeItem('login-state'); // Auth flow completed for the SSO login

          if (!this.currentAuth().currentUser?.email) {
            console.error('SSO login failed', 'No current user');

            sessionStorage.setItem('login-error', 'SSO login failed');

            this.store.dispatch(new SignOutWithRedirect(true));
          } else {
            this.al.logAuthAccess(of(this.currentAuth().currentUser?.email), 'LOGIN');
          }
        }
      }
    });
  }

  /*
   * ORY authentication is performed in 3 steps, login states for the steps are:
   * 1. Redirected:    The user is redirected to the ORY login url
   * 2. Authenticated: The user is authenticated and Firebase login is called
   * 3. "Finished":    Teams update done and user is redirected to the surveys page
   */
  oryLogin(ssoDomain?: string) {
    const domain = ssoDomain.endsWith('-test') ? 'ory.test' : 'ory';

    const queryParams = this.store.selectSnapshot(RouterState.queryParams);

    console.log('ORY login', sessionStorage.getItem('login-state'));

    if (!sessionStorage.getItem('login-state')) {
      sessionStorage.setItem('login-state', 'redirected');

      return this.http
        .request(Requests.Get, `https://${domain}.zef.fi/self-service/login/browser`, {
          responseType: 'json',
          withCredentials: true,
          params: new HttpParams({
            fromObject: {
              refresh: true,
              return_to: window.location.href,
            },
          }),
        })
        .pipe(
          catchError((e) => {
            console.log('ORY self-service login error', e);

            return of(null);
          }),
        )
        .subscribe((response: any) => {
          console.log('ORY self-service login response', response);

          if (response) {
            const params: any = { method: 'saml' };

            response.ui.nodes
              .filter((n: any) => n.group === 'saml')
              .forEach((n: any) => {
                if (n.attributes.value === ssoDomain) {
                  params[n.attributes.name] = n.attributes.value;
                }
              });

            console.log('ORY self-service login params', params);

            const urlPrams: string = new URLSearchParams(Object.entries(params)).toString();

            console.log('ORY self-service login query params', urlPrams);

            window.location.href = response.ui.action + '&' + urlPrams;
          } else {
            sessionStorage.removeItem('login-state'); // Reset the auth flow

            sessionStorage.setItem('login-error', 'SSO login error');

            this.store.dispatch(new SignOutWithRedirect(true));
          }
        });
    } else {
      sessionStorage.setItem('login-state', 'authenticated');

      // The oryLogin function updates teams and returns a Firebase token

      return this.http
        .request(Requests.Get, `https:${environment.webAddress}/oryLogin`, {
          responseType: 'json',
          withCredentials: true,
          params: new HttpParams({
            fromObject: { domain, organization: queryParams.zefOrg || '' },
          }),
          headers: new HttpHeaders({
            'NGSW-Bypass': 'true',
          }),
        })
        .pipe(
          catchError(() => of(void 0)),
          switchMap((response: AuthCustomResponse) =>
            !response?.token
              ? of(null)
              : this.store.dispatch(new ResetUserInfo()).pipe(switchMap(() => this.signInWithToken(response?.token))),
          ),
        )
        .subscribe((user: User | null | undefined) => {
          console.log('ORY firebase token login user', user);

          sessionStorage.removeItem('login-state'); // Auth flow completed for the ORY login

          if (user) {
            this.al.logAuthAccess(of(user.email), 'LOGIN');

            this.store.dispatch(
              new Navigate([`/surveys`], { zefOrg: null }, { replaceUrl: true, queryParamsHandling: 'merge' }),
            );
          } else {
            if (user === null) {
              sessionStorage.setItem('login-error', 'SSO login error');
            } else {
              sessionStorage.setItem('login-error', 'SSO token error');
            }

            this.store.dispatch(new SignOutWithRedirect(true));
          }
        });
    }
  }

  oryLogout(ssoDomain: string, logoutUrl: string) {
    const domain = ssoDomain.endsWith('-test') ? 'ory.test' : 'ory';

    return this.http
      .request(Requests.Get, `https://${domain}.zef.fi/self-service/logout/browser`, {
        responseType: 'json',
        withCredentials: true,
        params: new HttpParams({
          fromObject: {
            return_to: logoutUrl,
          },
        }),
      })
      .pipe(
        catchError((error) => {
          console.log('ORY logout error', error);

          sessionStorage.setItem('login-error', 'SSO logout error');

          return of(null);
        }),
      );
  }

  /**
   * General account management helper functions.
   */

  public updatePassword(password: string): Observable<boolean> {
    return this.runWithUser((user) => user.updatePassword(password)).pipe(
      map(() => true),
      catchError((error) => {
        console.error('Password update failed', error);

        return throwError(null);
      }),
    );
  }

  public signInWithToken(token: string): Observable<User | null> {
    return this.runWithAuth((auth) => auth.signInWithCustomToken(token)).pipe(
      map((cred) => cred?.user || null),
      catchError((error) => {
        console.error('Failed to sign in with custom token', error);

        return throwError(null);
      }),
    );
  }

  public reAuthenticateWithGoogle(): Observable<boolean> {
    const provider = new firebase.auth.GoogleAuthProvider();

    return this.runWithUser((user) => user.reauthenticateWithPopup(provider)).pipe(map(() => true));
  }

  public signInWithEmailPassword(email: string, password: string): Observable<User | null> {
    return this.al
      .logAuthAccess(
        this.runWithAuth((auth) => auth.signInWithEmailAndPassword(email, password)),
        'LOGIN',
        email,
      )
      .pipe(
        map((cred) => cred?.user || null),
        catchError((error) => {
          console.error('Email sign-in failed', error);

          return throwError(error);
        }),
      );
  }

  public reAuthenticateWithEmailPassword(email: string, password: string): Observable<User | null> {
    const credential = firebase.auth.EmailAuthProvider.credential(email, password);

    return this.runWithUser((user) => user.reauthenticateWithCredential(credential)).pipe(
      map((cred) => cred?.user || null),
      catchError((error) => {
        console.error('Email sign-in failed', error);

        return throwError(error);
      }),
    );
  }

  public sendEmailPasswordResetEmail(email: string): Observable<boolean> {
    return this.runWithAuth((auth) => auth.sendPasswordResetEmail(email)).pipe(
      map(() => true),
      catchError((error) => {
        console.error('Email sending failed', error);

        return throwError(error);
      }),
    );
  }

  public verifyEmailPasswordResetCode(actionCode: any): Observable<string> {
    return this.runWithAuth((auth) => auth.verifyPasswordResetCode(actionCode)).pipe(
      catchError((error) => {
        console.error('Code verification failed', error);

        return throwError(error);
      }),
    );
  }

  public verifyEmailPasswordEmailAddress(actionCode: any): Observable<boolean> {
    return this.runWithAuth((auth) => auth.applyActionCode(actionCode)).pipe(
      map(() => true),
      catchError((error) => {
        console.error('Code verification failed', error);

        return throwError(error);
      }),
    );
  }

  public confirmEmailPasswordEmailRecovery(actionCode: any): Observable<string> {
    return this.runWithAuth((auth) =>
      auth.checkActionCode(actionCode).then((info) => auth.applyActionCode(actionCode).then(() => info?.data?.email)),
    ).pipe(
      catchError((error) => {
        console.error('Code confirmation failed', error);

        return throwError(error);
      }),
    );
  }

  public confirmEmailPasswordResetPassword(actionCode: any, newPassword: string): Observable<void> {
    return this.runWithAuth((auth) => auth.confirmPasswordReset(actionCode, newPassword)).pipe(
      catchError((error) => {
        console.error('Reset confirmation failed', error);

        return throwError(error);
      }),
    );
  }

  private runOutside<T>(retriever: () => Promise<T>): Observable<T> {
    return runOutsideZone(this.zone)(retriever);
  }

  private runWithUser<T>(run: (user: User) => Promise<T>): Observable<T> {
    return this.currentUser().pipe(switchMap((user) => this.runOutside<T>(() => run(user))));
  }

  private runWithAuth<T>(run: (auth: firebase.auth.Auth) => Promise<T>): Observable<T> {
    const auth = this.currentAuth();

    return this.runOutside<T>(() => run(auth));
  }

  public sendInvite(token: string, verify?: boolean, redirect?: string): Observable<any> {
    const locale = this.store.selectSnapshot(PrefsState.language);

    return this.za
      .post(
        'invite/signup',
        {},
        {
          type: verify ? 'verify' : 'login',
          locale,
          redirect: redirect || null,
        },
      )
      .pipe(
        catchError((error: any) => {
          this.store.dispatch(new ServerError(error));

          return of(error);
        }),
      );
  }

  public checkInvites(email: string): Observable<InviteData[]> {
    return this.db
      .list<InviteData>(`/invites`, (ref) => ref.orderByChild('email').equalTo(email))
      .snapshotChanges()
      .pipe(take(1), mapListKeys());
  }

  public getInvite(inviteKey: string): Observable<InviteData> {
    const teamInvite$ = this.db.object<InviteData>(`/invites/${inviteKey}`).snapshotChanges().pipe(mapObjectKey());

    const funnelInvite$ = this.db
      .object<InviteData>(`/funnelinvites/${inviteKey}`)
      .snapshotChanges()
      .pipe(
        mapObjectKey(),
        switchMap((inviteData: InviteData) => {
          if (!inviteData) {
            return of(null);
          } else {
            return of(inviteData);
          }
        }),
      );

    return combineLatest([teamInvite$, funnelInvite$]).pipe(
      map(([teamInvite, funnelInvite]) => teamInvite || funnelInvite),
    );
  }

  verifyCustomLogin(verifyKey: string): Observable<string> {
    return this.za.post<AuthCustomResponse>('auth/custom', { verifyKey }).pipe(
      switchMap((response: AuthCustomResponse) =>
        !response?.token
          ? of(null)
          : this.store.dispatch(new ResetUserInfo()).pipe(switchMap(() => this.signInWithToken(response.token))),
      ),
      map((user) => user?.uid),
    );
  }

  verifyScaKey(scaKey: string) {
    return this.za.post<AuthCustomResponse>('auth/custom', { scaKey }).pipe(
      switchMap((response: AuthCustomResponse) =>
        !response?.token
          ? of(null)
          : this.store.dispatch(new ResetUserInfo()).pipe(switchMap(() => this.signInWithToken(response.token))),
      ),
      map((user) => Boolean(user)),
    );
  }

  verifyPrivateLink(linkKey: string, email?: string) {
    return this.za
      .post<AuthCustomResponse>('auth/custom', { linkKey, email })
      .pipe(map((response) => ({ ...response?.share, token: response.token })));
  }

  loginExpires(userUid: string) {
    return timer(60000).pipe(
      switchMap(() => this.db.object<any>(`/auth/login/${userUid}/loginExpires`).snapshotChanges()),
    );
  }

  getPollToken(surveyKey: string, teamKey: string, linkKey: string, sessionKey: string) {
    return this.za.post<any>('auth/livepoll', { surveyKey, teamKey, linkKey, sessionKey });
  }

  getSSOSettings(ssoDomain: string) {
    return ssoDomain
      ? this.db.object<any>(`/sso/${ssoDomain}/loginSettings`).snapshotChanges().pipe(mapObjectKey())
      : of(null);
  }

  checkSSODomain(emailDomain: string) {
    return !emailDomain
      ? of(null)
      : this.db
          .object<any>(`/domains/${emailDomain.replace(/\./g, ',')}`)
          .valueChanges()
          .pipe(
            take(1),
            map((value) => !!value),
          );
  }

  getSignupData(teamHandle: string): Observable<SignupData | null> {
    if (teamHandle) {
      return this.db
        .list<SignupData>(`/signups`, (ref) => ref.orderByChild(`handle`).equalTo(teamHandle))
        .snapshotChanges()
        .pipe(
          take(1),
          mapListKeys('parentTeam'),
          map((data) => data[0] || null),
          tap((data) => this.signup$.next(data)),
        );
    } else {
      const userKey = this.store.selectSnapshot(AuthState.userUid);
      return this.userSignupData(userKey);
    }
  }

  clearSignupData() {
    const userKey = this.store.selectSnapshot(AuthState.userUid);
    this.db.object<SignupData | boolean>(`/auth/signup/${userKey}`).remove();
    this.signup$.next(null);
  }

  userSignupData(userKey: string) {
    return this.db
      .object<SignupData>(`/auth/signup/${userKey}`)
      .valueChanges()
      .pipe(
        take(1),
        tap((data) => this.signup$.next(data)),
      );
  }

  saveSignupData() {
    const userKey = this.store.selectSnapshot(AuthState.userUid);
    const data: SignupData | boolean = this.signup$.value || true;
    this.db.object<SignupData | boolean>(`/auth/signup/${userKey}`).set(data);
  }
}
