import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Params } from '@angular/router';
import { AuthError, AuthView } from '@auth/auth.enum';
import { environment } from '@env/environment';
import { Navigate } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { InviteData } from '@shared/models/account.model';
import { UserInfo } from '@shared/models/auth.model';
import { AuthManager } from '@shared/services/auth-manager.service';
import { CreateTeam, GetUser, JoinTeam } from '@shared/states/account.actions';
import { AccountState } from '@shared/states/account.state';
import {
  AuthenticationError,
  CancelAuthSignup,
  GetInvite,
  SendVerificationEmail,
  SignOutWithRedirect,
} from '@shared/states/auth.actions';
import { AuthState } from '@shared/states/auth.state';
import { PrefsState } from '@shared/states/prefs.state';
import { RouterState } from '@shared/states/router.state';
import { isEmail } from 'class-validator';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

const defaultViewState = {
  googleError: '',
  microsoftError: '',
  emailError: '',
  passwordError: '',
  hasGoogleProvider: false,
  hasEmailProvider: false,
  resetError: false,
  email: '',
  loading: false,
  isInvite: false,
  invite: null,
};

@Component({
  selector: 'auth-sidenav',
  templateUrl: './auth-sidenav.component.html',
})
export class AuthSidenav implements OnInit {
  readonly wwwAddress = `https:${environment.wwwAddress}`;
  public View = AuthView;

  @Select(PrefsState.isDesktop)
  isDesktop$!: Observable<boolean>;

  @Select(PrefsState.isMobile)
  isMobile$!: Observable<boolean>;

  @Select(AuthState.info)
  userInfo$!: Observable<UserInfo>;

  @Select(RouterState.queryParams)
  queryParams$!: Observable<Params>;

  @Select(RouterState.url)
  url$!: Observable<string>;

  @Select(AuthState.invite)
  invite$!: Observable<InviteData>;

  invites$ = new BehaviorSubject<InviteData[]>([]);
  view$ = new BehaviorSubject<AuthView | null>(null);
  error$ = new BehaviorSubject<{ code: AuthError; message?: string }>(null);
  state$ = new BehaviorSubject(defaultViewState);
  signup$ = this.am.signup$.asObservable();

  @Output() signupDone = new EventEmitter();
  @LocalStorage('report-team') privateReportTeamKey!: string | undefined;

  constructor(
    private store: Store,
    private am: AuthManager,
  ) {}

  ngOnInit(): void {
    this.url$.subscribe((url) => {
      if (url) {
        const initialView = this.initialView(url);
        this.view$.next(initialView);
      } else {
        this.view$.next(AuthView.SIGNUP_SELECT_METHOD);
      }
    });
  }

  private initialView(url: string) {
    const currentView = this.view$.value;
    const loginError = sessionStorage.getItem('login-error');
    const userInfo = this.store.selectSnapshot(AuthState.info);
    const inviteData = this.store.selectSnapshot(AuthState.invite);
    const isInviteUrl = inviteData && url.startsWith(`/signup/${inviteData.$key}`);
    const isInviteExpired = Boolean(inviteData?.accepted) || Boolean(inviteData?.rejected);
    const isInvitedOk = !!inviteData?.email && !isInviteExpired;
    const isInvitedSignedIn = userInfo?.email === inviteData?.email;
    const isAnonymous = !!userInfo?.isAnonymous;
    const isUserVerified = !!userInfo?.email && !!userInfo?.emailVerified;
    const signupData = this.am.signup$.value;
    const isSignupUrl = url?.replace(/\?.*/, '').endsWith(signupData?.handle);

    // const debugData = {
    //   url,
    //   currentView,
    //   loginError,
    //   userInfo,
    //   inviteData,
    //   isInviteUrl,
    //   isInviteExpired,
    //   isInvitedOk,
    //   isInvitedSignedIn,
    //   isAnonymous,
    //   isUserVerified,
    //   signupData,
    //   isSignupUrl,
    // };
    // console.warn('AuthSidenav.initialView', debugData);

    if (url.startsWith('/signup')) {
      if (isAnonymous && isSignupUrl) {
        return AuthView.SIGNUP_SELECT_METHOD;
      } else if (isInviteExpired) {
        this.error$.next({ code: AuthError.INVITE_KEY_EXPIRED });
        return AuthView.INVITE_ERROR;
      } else if (isInviteUrl && !isInvitedOk) {
        this.error$.next({ code: AuthError.INVITE_KEY_INVALID });
        return AuthView.INVITE_ERROR;
      } else if (!isAnonymous && !isInvitedSignedIn && isInvitedOk) {
        this.error$.next({ code: AuthError.INVITE_WRONG_ACCOUNT });
        return AuthView.INVITE_ERROR;
      } else if (isUserVerified) {
        this.emailVerified();
        return currentView;
      } else {
        return AuthView.SIGNUP_SELECT_METHOD;
      }
    } else if (url.startsWith('/invite')) {
      if (isInvitedSignedIn) {
        return AuthView.INVITE_ACCEPT;
      } else if (isInviteExpired) {
        this.error$.next({ code: AuthError.INVITE_KEY_EXPIRED });
        return AuthView.INVITE_ERROR;
      } else if (!isAnonymous && !isInvitedSignedIn && isInvitedOk) {
        this.error$.next({ code: AuthError.INVITE_WRONG_ACCOUNT });
        return AuthView.INVITE_ERROR;
      } else if (isAnonymous && isInvitedOk) {
        return AuthView.SIGNIN_SELECT_INVITE;
      } else {
        this.error$.next({ code: AuthError.INVITE_KEY_INVALID });
        return AuthView.INVITE_ERROR;
      }
    } else if (url.startsWith('/login')) {
      if (loginError) {
        this.error$.next({ code: AuthError.SSO_LOGIN_ERROR, message: loginError });

        sessionStorage.removeItem('login-error');
      } else {
        this.error$.next(null);
      }

      if (isInvitedOk) {
        return AuthView.SIGNIN_SELECT_INVITE;
      } else if (!!userInfo?.email && !userInfo?.emailVerified) {
        return AuthView.SIGNUP_CHECK_EMAIL;
      } else {
        return AuthView.SIGNIN_SELECT_METHOD;
      }
    } else if (url.startsWith('/private')) {
      return AuthView.REPORT_AUTHENTICATION;
    } else if (url.startsWith('/admin/orgs')) {
      return AuthView.SIGNUP_SELECT_METHOD;
    } else {
      return currentView;
    }
  }

  private updateState(change: Partial<typeof defaultViewState>) {
    this.state$.next({ ...this.state$.value, ...change });
  }

  private checkUserInvites(): Observable<InviteData[]> {
    const userInfo = this.store.selectSnapshot(AuthState.info);
    return this.am.checkInvites(userInfo?.email);
  }

  private processUserInvites(invites: InviteData[]) {
    if (invites.length > 0) {
      this.invites$.next(invites);
    }

    const userInfo = this.store.selectSnapshot(AuthState.info);
    const userData = this.store.selectSnapshot(AccountState.user);
    const hasActiveTeam = !!userData?.team;
    const isEmailConnected = this.store.selectSnapshot(AuthState.isEmailConnected);
    const isGoogleConnected = this.store.selectSnapshot(AuthState.isGoogleConnected);
    const isMicrosoftConnected = this.store.selectSnapshot(AuthState.isMicrosoftConnected);
    const inviteData = this.store.selectSnapshot(AuthState.invite);
    const isInvitedSignedIn = userInfo?.email === inviteData?.email;
    const isInviteExpired = Boolean(inviteData?.accepted) || Boolean(inviteData?.rejected);
    const isInvitedOk = !!inviteData?.email && !isInviteExpired;

    // const debugData = {
    //   invites,
    //   userInfo,
    //   userData,
    //   hasActiveTeam,
    //   isEmailConnected,
    //   isGoogleConnected,
    //   isMicrosoftConnected,
    //   inviteData,
    //   isInvitedSignedIn,
    //   isInviteExpired,
    //   isInvitedOk,
    // };
    // console.warn('AuthSidenav.processUserInvites', debugData);

    if (isInvitedOk && !isInvitedSignedIn) {
      this.error$.next({ code: AuthError.INVITE_WRONG_ACCOUNT });
      this.view$.next(AuthView.INVITE_ERROR);
    } else if (hasActiveTeam) {
      this.store.dispatch(new Navigate(['/']));
    } else if (isGoogleConnected || isMicrosoftConnected) {
      this.view$.next(AuthView.SIGNUP_CONFIRM_PROVIDER);
    } else if (isEmailConnected) {
      if (invites.length === 0) {
        this.finishEmailSignup(null);
      } else {
        this.view$.next(AuthView.SIGNUP_VERIFIED);
      }
    }
  }

  private processInvitesError(error: any) {
    this.updateState({ googleError: error?.message || '' });
    if (error.email) {
      this.updateState({ email: error.email });
    }
    this.handleAuthError(error.code);
  }

  private emailVerified() {
    this.am
      .refreshToken()
      .pipe(switchMap(() => this.checkUserInvites()))
      .subscribe({
        next: (invites) => this.processUserInvites(invites),
        error: (error) => this.processInvitesError(error),
      });
  }

  selectSignUpMethod(method: 'google' | 'microsoft' | 'email' | 'change', email?: string) {
    if (location.pathname.startsWith('/admin/orgs')) {
      return;
    }

    if (method === 'email') {
      this.view$.next(AuthView.SIGNUP_CONFIRM_EMAIL);
    } else if (method === 'google') {
      this.updateState({ googleError: '' });
      this.view$.next(AuthView.SIGNUP_GOOGLE_PROGRESS);

      this.am.saveSignupData();
      this.am
        .linkGoogle(email)
        .pipe(
          switchMap(() => this.am.refreshToken()),
          switchMap(() => this.checkUserInvites()),
        )
        .subscribe({
          next: (invites) => this.processUserInvites(invites),
          error: (error) => this.processInvitesError(error),
        });
    } else if (method === 'microsoft') {
      this.updateState({ microsoftError: '' });
      this.view$.next(AuthView.SIGNUP_MICROSOFT_PROGRESS);

      this.am.saveSignupData();
      this.am
        .linkMicrosoft(email)
        .pipe(
          switchMap(() => this.am.refreshToken()),
          switchMap(() => this.checkUserInvites()),
        )
        .subscribe({
          next: (invites) => this.processUserInvites(invites),
          error: (error) => this.processInvitesError(error),
        });
    } else if (method === 'change') {
      this.view$.next(AuthView.SIGNIN_SELECT_METHOD);

      this.store.dispatch(new Navigate(['/login']));
    }
  }

  selectSignInMethod(event: any) {
    if (event?.method === 'google') {
      this.am.signInWithGoogle().subscribe();
    } else if (event?.method === 'microsoft') {
      this.am.signInWithMicrosoft().subscribe();
    } else if (event?.method === 'sso') {
      this.view$.next(AuthView.SSO_EMAIL_LOGIN);
    } else if (event?.method === 'init') {
      this.error$.next(null);
      this.view$.next(AuthView.SIGNIN_SELECT_METHOD);
    } else if (event?.method === 'change') {
      this.view$.next(AuthView.SIGNUP_SELECT_METHOD);

      this.store.dispatch(new Navigate(['/signup']));
    } else if (event?.method === 'password') {
      this.signInWithEmail(event?.email, event?.password);
    }
  }

  private signInWithEmail(email: string, password: string) {
    if (!isEmail(email)) {
      this.store.dispatch(new AuthenticationError(AuthError.INVALID_EMAIL));
      return this.error$.next({ code: AuthError.INVALID_EMAIL });
    }

    this.am
      .signInWithEmailPassword(email, password)
      .pipe(
        catchError((error) => {
          if (error?.code === 'auth/user-not-found') {
            this.error$.next({ code: AuthError.ACCOUNT_NOT_EXIST, message: email });
          } else if (error?.code === 'auth/invalid-email') {
            this.error$.next({ code: AuthError.INVALID_EMAIL });
          } else if (error?.code === 'auth/invalid-credential') {
            this.error$.next({ code: AuthError.NO_PASSWORD_MATCH });
            this.store.dispatch(new AuthenticationError(AuthError.NO_PASSWORD_MATCH));
          } else if (error?.code === 'auth/wrong-password') {
            this.store.dispatch(new AuthenticationError(AuthError.NO_PASSWORD_MATCH));
            this.error$.next({ code: AuthError.NO_PASSWORD_MATCH });
          }

          return of(null);
        }),
      )
      .subscribe();
  }

  finishEmailSignup(inviteKey: string | null) {
    this.view$.next(AuthView.INVITE_ACCEPT);

    if (!inviteKey) {
      const parentTeam = this.am.signup$.value?.parentTeam;
      const userKey = this.store.selectSnapshot(AuthState.userUid);
      this.store.dispatch([new CreateTeam(parentTeam), new GetUser(userKey)]);

      this.store.select(AccountState.activeTeamKey).subscribe((activeTeamKey) => {
        if (activeTeamKey) {
          this.store.dispatch(new Navigate(['/']));
          this.am.clearSignupData();
        }
      });
    } else {
      this.store.dispatch([new JoinTeam(inviteKey), new GetInvite(inviteKey)]);
      this.am.clearSignupData();
    }
  }

  finishProviderSignup(inviteKey: string) {
    this.signupDone.emit();

    const isInvite = !!inviteKey || this.store.selectSnapshot(AuthState.isInvite);

    this.updateState({ isInvite });
    this.signupDone.emit();

    if (!isInvite) {
      const parentTeam = this.am.signup$.value?.parentTeam;
      const userKey = this.store.selectSnapshot(AuthState.userUid);
      this.store.dispatch([new CreateTeam(parentTeam), new GetUser(userKey)]);

      this.store.select(AccountState.activeTeamKey).subscribe((activeTeamKey) => {
        if (activeTeamKey) {
          this.am.clearSignupData();
          this.store.dispatch(new Navigate(['/']));
        }
      });
    } else {
      const invite = this.store.selectSnapshot(AuthState.invite);
      const key = inviteKey || invite?.$key;

      this.store.dispatch([new JoinTeam(key), new GetInvite(key)]);
      this.view$.next(AuthView.INVITE_ACCEPT);
    }
  }

  signUpWithEmail(data: any) {
    if (data !== null) {
      const { email, password } = data;

      this.updateState({ email, loading: true });

      this.am.saveSignupData();
      this.am
        .linkPassword(email, password)
        .pipe(
          tap(() => {
            const redirect = this.store.selectSnapshot(RouterState.queryParams).redirect;
            const lang = this.store.selectSnapshot(PrefsState.language);
            const isInvite = this.store.selectSnapshot(AuthState.isInvite);

            this.updateState({ isInvite });
            this.view$.next(AuthView.SIGNUP_CHECK_EMAIL);
            this.signupDone.emit();

            if (!isInvite) {
              this.store.dispatch(new SendVerificationEmail(redirect, lang));
            } else {
              const invite = this.store.selectSnapshot(AuthState.invite);

              this.store.dispatch(new JoinTeam(invite?.$key));
              this.view$.next(AuthView.INVITE_ACCEPT);
            }
          }),
          catchError((error) => {
            if (typeof error.code === 'string') {
              this.updateState({ emailError: error.message });
              this.handleAuthError(error.code);
            }

            return of(null);
          }),
        )
        .subscribe(() => this.updateState({ loading: false }));
    } else {
      this.view$.next(AuthView.SIGNUP_SELECT_METHOD);
    }
  }

  continueProviderSignup() {
    this.view$.next(AuthView.SIGNUP_CONFIRM_PROVIDER);
  }

  isEmailProvider(userInfo: UserInfo) {
    return userInfo?.providerData[0]?.providerId === 'password';
  }

  isGoogleProvider(userInfo: UserInfo) {
    return userInfo?.providerData[0]?.providerId === 'google.com';
  }

  isMicrosoftProvider(userInfo: UserInfo) {
    return userInfo?.providerData[0]?.providerId === 'microsoft.com';
  }

  signUpWithNewEmail() {
    this.updateState({ emailError: '', googleError: '', microsoftError: '' });
    this.view$.next(AuthView.SIGNUP_CONFIRM_EMAIL);

    this.am.signOut().subscribe();
  }

  sendPasswordResetEmail(email: string | null) {
    if (email) {
      this.am.sendEmailPasswordResetEmail(email).subscribe();
    } else {
      this.updateState({ emailError: '', googleError: '', microsoftError: '' });
      this.view$.next(AuthView.SIGNUP_SELECT_METHOD);
      this.am.unlinkPassword().subscribe();
    }
  }

  signInForReport({ email, linkKey }) {
    if (!email) {
      this.error$.next(null);
    } else if (!isEmail(email)) {
      this.error$.next({ code: AuthError.INVALID_EMAIL });
    } else {
      this.error$.next(null);

      this.am
        .verifyPrivateLink(linkKey, email)
        .pipe(
          switchMap((response) => {
            if (response) {
              return this.am.signInWithToken(response.token).pipe(
                tap(() => {
                  const { surveyKey, reportKey, teamKey } = response;

                  this.privateReportTeamKey = teamKey;

                  setTimeout(() => {
                    this.store.dispatch(
                      new Navigate(
                        ['/report', surveyKey, reportKey],
                        {},
                        {
                          skipLocationChange: true,
                        },
                      ),
                    );
                  }, 50);
                }),
              );
            } else {
              throw Error('Unable to verify private link');
            }
          }),
          catchError(() => {
            this.error$.next({ code: AuthError.NO_REPORT_EMAIL_MATCH });
            return of(null);
          }),
        )
        .subscribe();
    }
  }

  public closeSignup(): boolean {
    const signupState = this.view$.value;

    if (signupState === AuthView.SIGNUP_CHECK_EMAIL) {
      this.store.dispatch(new SignOutWithRedirect(true));
      this.view$.next(AuthView.SIGNIN_SELECT_METHOD);
    } else if (signupState === AuthView.SIGNUP_CONFIRM_PROVIDER) {
      this.view$.next(AuthView.SIGNUP_CANCEL);
      return true;
    } else if (signupState === AuthView.SIGNUP_CANCEL) {
      this.store.dispatch(new CancelAuthSignup());
      this.view$.next(AuthView.SIGNIN_SELECT_METHOD);
    }

    return false;
  }

  public cancelSignup() {
    this.store.dispatch(new CancelAuthSignup());
    this.view$.next(AuthView.SIGNUP_SELECT_METHOD);
  }

  private handleAuthError(code: string) {
    switch (code) {
      case 'auth/credential-already-in-use':
      case 'auth/email-already-in-use':
      case 'auth/provider-already-linked':
        return this.view$.next(AuthView.SIGNUP_ACCOUNT_EXIST);

      default:
        return this.view$.next(AuthView.SIGNUP_SELECT_METHOD);
    }
  }
}
