import { environment } from '@env/environment';
import { Answers } from '@shared/enums/answers.enum';
import { defaultOutcomeOptions, OutcomeOptions, Outcomes } from '@shared/enums/outcomes.enum';
import { OtherProperty, SurveyProperty } from '@shared/enums/properties.enum';
import { Questions } from '@shared/enums/questions.enum';
import { Rights } from '@shared/enums/rights.enum';
import { LocalesData, Translatable } from '@shared/models/locale.model';
import { OrderData } from '@shared/models/order.model';
import { PlanUsage } from '@shared/models/plan.model';
import { IdentityData } from '@shared/models/prefs.model';
import { ChartSettings, GridItem } from '@shared/models/report.model';
import { ColorStyles, FontStyles, ImageStyles, ThemeStyles } from '@shared/models/styles.model';
import { ShareTemplates, ShareType } from '@shared/models/survey-shares.model';
import { AreaMode, TimeValue } from '@shared/models/utility.model';
import { VideoEmbedInfo } from '@shared/models/video-embed.model';
import { mergeObjectWithProperties } from '@shared/utilities/object.utilities';
import { toPreviewString } from '@shared/utilities/properties.utilities';
import { Observable } from 'rxjs';

export enum BuilderType {
  Welcome = 'welcome',
  Question = 'question',
  Group = 'group',
  Result = 'result',
  Trigger = 'trigger',
}

/* eslint-disable @typescript-eslint/no-shadow */
export enum ViewState {
  Loading = 'loading',
  Welcome = 'welcome',
  Questions = 'questions',
  Outcomes = 'outcomes',
}
/* eslint-enable @typescript-eslint/no-shadow */

export interface BuildItem<T = any> {
  type: BuilderType;
  $key?: string;
  data?: T;
  group?: T;
  info?: boolean;
}

export class Survey {
  public key: string;
  public category: string;
  public discoverable: boolean;
  public time: number | null = null;

  public owner: Observable<any> | null;
  public rights: Observable<Rights> | null;

  public survey: Observable<SurveyData>;
  public design: Observable<DesignData>;
  public locales: Observable<LocalesData>;
  public sharing: Observable<SharingData>;
  public scoring: Observable<SurveyScoring>;
  public outcomes: Observable<OutcomeData[]>;
  public questions: Observable<QuestionData[]>;
  public triggers: Observable<TriggerData[]>;

  public release: Observable<ReleaseData> | null;

  public constructor(data: SurveyInterface) {
    this.key = data.key;

    this.category = data.category;

    this.owner = data.owner || null;
    this.rights = data.rights || null;

    this.survey = data.survey;
    this.design = data.design;
    this.locales = data.locales;
    this.sharing = data.sharing;
    this.scoring = data.scoring;
    this.outcomes = data.outcomes;
    this.questions = data.questions;
    this.triggers = data.triggers;

    this.release = data.release || null;
  }
}

export interface SurveyHistory {
  $key: string;
  owner: Observable<IdentityData>;
  survey: Observable<SurveyData>;
  questions: Observable<QuestionData[]>;
}

export interface SurveyHistoryModel {
  $key: string;
  survey: SurveyData | null;
  owner: IdentityData | null;
  questions: QuestionData[];
}

export interface SurveyModel {
  survey: SurveyData | null;
  design: DesignData | null;
  locales: LocalesData | null;
  sharing: SharingData | null;
  release: ReleaseData | null;
  scoring: SurveyScoring | null;
  template?: TemplateData;
  outcomes: OutcomeData[] | null;
  questions: QuestionData[] | null;
  triggers: TriggerData[] | null;
  usage: PlanUsage | null;
  owner: IdentityData | null;
  rights?: Rights;
  history?: SurveyHistoryModel;
  respondents?: number;
  hash?: string;
}

export enum SettingType {
  QuestionEditingDisabled = 'question-editing-disabled',
  QuestionDeletingDisabled = 'question-deleting-disabled',
  QuestionSkipAnonymity = 'question-skip-anonymity',
  QuestionExtraSensitiveAnonymity = 'question-extra-sensitive-anonymity',
  QuestionIsIncludedOverallAverage = 'question-is-included-overall-average',
  SurveyLogicDisabled = 'survey-logic-disabled',
  SurveyAnonymityDisabled = 'survey-anonymity-disabled',
  SurveySetupDisabled = 'survey-setup-disabled',
  SurveyWelcomeDisabled = 'survey-welcome-disabled',
  SurveySocialDisabled = 'survey-social-disabled',
  QuestionSettingsEnabled = 'question-settings-enabled',
  QuestionLimitEnabled = 'question-limit-enabled',
  SurveyShareInviteDisabled = 'survey-invites-disabled',
  SurveyNotificationDisabled = 'survey-notifications-disabled',
}

export interface SurveySetting {
  type: SettingType;
  value: any;
}

export interface TemplateData {
  uuid: string;
  key: string;
  draft: string;
  templateKey: string;
  reportKey: string;
  reportURL?: string;
  webkey: string;
  category: string;
  name: string;
  language: string;
  author: {
    name: string;
    intro?: string;
    video?: string;
  };
  questionCount: number;
  teamKey: string;
  discoverable: boolean;
}

export interface TemplateCategory {
  $key: string;
  title: string;
  name: string;
  language: string;
}

export function templatePlayerLink(template: TemplateData) {
  return 'https:' + environment.publicUrl + '/s/templates/' + template.key + '?zefPreview=true';
}

export function templateReportLink(template: TemplateData) {
  return 'https:' + environment.publicUrl + '/r/templates/' + template.key;
}

export function templateAuthorVideoLink(template: TemplateData) {
  const url = template && template.author && template.author.video;
  return !url ? url : url.replace('https://youtu.be/', 'https://www.youtube.com/embed/');
}

export function templateAuthorIntroLink(template: TemplateData) {
  return template && template.author && template.author.intro;
}

export interface SurveyInterface {
  key: string;

  category?: string;

  owner?: Observable<any>;
  rights?: Observable<Rights>;

  survey: Observable<SurveyData>;
  design: Observable<DesignData>;
  locales: Observable<LocalesData>;
  scoring: Observable<SurveyScoring>;
  sharing: Observable<SharingData>;
  outcomes: Observable<OutcomeData[]>;
  questions: Observable<QuestionData[]>;
  triggers: Observable<TriggerData[]>;

  release?: Observable<ReleaseData>;
}

export interface ScoreableInterface {
  $key: string;

  /**
   * @deprecated use $key instead
   */
  scoring: string | null;
}

export enum LogicOperator {
  Or = 'OR',
  And = 'AND',
}

export interface LogicStatement {
  operator?: LogicOperator;
}

export interface LogicItemStatement<T extends LogicStatement = LogicStatement> extends LogicStatement {
  $key?: string;
  index: number;
  logic: T;
  operator: LogicOperator;
}

export interface WhenLogicStatement extends OrderData {
  logic: DateTimeLogic;
  operator: LogicOperator;
}

export interface ChoiceLogic extends LogicStatement {
  answers: string[];
}

export interface SingleChoiceLogic extends ChoiceLogic {
  operator: LogicOperator.Or;
}

export interface InputLogic extends LogicStatement {
  answer: boolean;
}

export interface Slider2DLogic extends LogicStatement {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;
  mode?: AreaMode;
}

export interface SliderLogic extends LogicStatement {
  min: number;
  max: number;
}

export interface DateTimeLogic extends LogicStatement {
  operator?: LogicOperator.Or;
  dateFrom?: Date;
  dateUntil?: Date;
  timeFrom?: TimeValue;
  timeUntil?: TimeValue;
}

export interface WithLogic {
  showIf: { [questionKey: string]: LogicItemStatement };
  showWhen?: WhenLogicStatement[];
  operator?: LogicOperator;
}

export type CreateCommand =
  | 'create_empty'
  | 'create_duplicate'
  | 'create_from_template'
  | 'create_from_blueprint'
  | 'import_published'
  | 'import_draft'
  | 'import_template'
  | 'import_blueprint'
  | 'edit_blueprint'
  | 'update_blueprint'
  | 'delete_blueprints';

export interface CreateOptions {
  command: CreateCommand;
  language: string;
  name: string;
  logo: string;
  fromTeam: string;
  teamKey: string;
  userKey: string;
  surveyKey: string;
  surveyUrl: string;
  templateKey: string;
  templateCode: string;
  blueprintKey: string;
  dryrun: boolean;
  admin: boolean;
  queryParams: string;
  json: string;
  keys: string[];
  questions?: QuestionData[];
}

export class SurveyData {
  created: any;
  modified: any;
  published: any;

  name: string = '';
  title: string = '';
  start: string = '';

  language: string = '';
  template: string = '';

  description: string = '';

  visible: boolean = false;
  welcome: boolean = false;
  policy: boolean = false;

  autojump: boolean = false;

  questionCount: number = 0;

  sharing: Rights = Rights.EDIT;

  results: Outcomes = Outcomes.GOODBYE;

  resultsOptions: OutcomeOptions = defaultOutcomeOptions;

  accessibilityBanner: boolean = false;

  users?: { [uid: string]: Rights };
  funnel?: any;
  groups: string[] = [];

  localesMigrated?: boolean;
  settings?: SurveySetting[];
  shareTemplates?: ShareTemplates;
  anonymous?: number;
  anonymityMigrated?: boolean;

  $key: string;

  defaultReportSettings?: DefaultReportSettings;
}

export type SurveyAnonymity = {
  anonymityMigrated: boolean;
  anonymous: number;
  isAnonymous: boolean;
  hasRespondents: boolean;
};

export type DefaultChartSettings = Partial<Pick<ChartSettings, 'scale' | 'showNumbers'>>;

export type AverageGroupSettings = {
  questions: string[];
};

export abstract class DefaultReportSettings extends OrderData {
  hideCharts?: string[];
  surveyChartSettings?: DefaultChartSettings;
  questionChartSettings?: Record<string, DefaultChartSettings>;
  averages?: Record<string, AverageGroupSettings>;
}

export class ReportData extends DefaultReportSettings {
  title: string = '';

  respondents: number = 0;

  latestAnswerTime: number = 0;

  latestContactEditTime?: number = 0;

  owner: string = '';
  ownerData?: IdentityData;

  lastModified?: { [user: string]: any };
  lastOpened?: { [user: string]: any };
  savingOnProgress?: boolean = false;
  savingError?: boolean = false;
  isAuthorUnsavedChanges?: boolean = false;
  grid?: GridItem[] = [];
  publicKey?: string = '';
  online?: boolean = false;
  shares?: { [email: string]: number };
  publicSettings?: { explorable: boolean; live: boolean } | null;
}

export class SidebarElements {
  teamName: boolean = true;
  teamLogo: boolean = true;

  restartSurvey: boolean = true;
  sharingButtons: boolean = true;

  selectLanguage: boolean = false;
}

export class DesignData {
  layout: string = '';

  extract: string = '';

  modified: boolean = false;

  logo: ImageStyles = new ImageStyles();

  font: FontStyles = new FontStyles();

  theme: ThemeStyles = new ThemeStyles();

  colors: ColorStyles = new ColorStyles();

  background: ImageStyles = new ImageStyles();

  sidebar: SidebarElements = new SidebarElements();

  constructor(data: Partial<DesignData> = {}) {
    mergeObjectWithProperties(this, data, ['logo', 'font', 'theme', 'colors', 'background', 'sidebar']);
  }
}

export class ServiceData {
  name: string = '';

  enabled: boolean = false;

  shareTitle: string = `{${SurveyProperty.NAME}}`;

  shareBody: string = `{${SurveyProperty.DESCRIPTION}}`;

  href: string = '';

  constructor(data: Partial<ServiceData>) {
    if (data && data.name != null) {
      if (['facebook', 'twitter'].includes(data.name)) {
        this.enabled = true;
      }

      Object.keys(data).forEach((key: string) => {
        this[key] = data[key];
      });
    }
  }
}

export class SharesData {
  imageData: any;

  enabled: boolean = false;

  titleText: string = `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}}`;
  buttonText: string = `{${OtherProperty.START_BUTTON}}`;

  email: ServiceData = new ServiceData({
    name: 'email',
    shareTitle: `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}}`,
    shareBody: toPreviewString(OtherProperty.OUTCOME_DESCRIPTION),
  });

  facebook: ServiceData = new ServiceData({
    name: 'facebook',
    shareTitle: `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}}`,
    shareBody: toPreviewString(OtherProperty.OUTCOME_DESCRIPTION),
  });

  twitter: ServiceData = new ServiceData({
    name: 'twitter',
    shareTitle: '',
    shareBody:
      `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}} ` +
      `{${OtherProperty.OUTCOME_DESCRIPTION}}`,
  });

  whatsapp: ServiceData = new ServiceData({
    name: 'whatsapp',
    shareTitle: '',
    shareBody:
      `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}} ` +
      `{${OtherProperty.OUTCOME_DESCRIPTION}}`,
  });

  google: ServiceData = new ServiceData({
    name: 'google',
    shareTitle: `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}}`,
    shareBody: toPreviewString(OtherProperty.OUTCOME_DESCRIPTION),
  });

  linkedin: ServiceData = new ServiceData({
    name: 'linkedin',
    shareTitle: `{${OtherProperty.OUTCOME_TITLE}} {${OtherProperty.MATCH_PERCENTAGE}}`,
    shareBody: toPreviewString(OtherProperty.OUTCOME_DESCRIPTION),
  });

  constructor(data: Partial<SharesData> = {}) {
    mergeObjectWithProperties(this, data, ['email', 'facebook', 'twitter', 'whatsapp', 'google', 'linkedin']);
  }
}

export class SharingData {
  $key: string;
  imageData: any;

  outcomeSharing: SharingData = new SharesData() as SharingData;

  enabled: boolean = false;

  titleText: string = `{${SurveyProperty.NAME}}`;
  buttonText: string = `{${OtherProperty.START_BUTTON}}`;

  email: ServiceData = new ServiceData({
    name: 'email',
  });

  facebook: ServiceData = new ServiceData({
    name: 'facebook',
  });

  twitter: ServiceData = new ServiceData({
    name: 'twitter',
    shareTitle: '',
    shareBody: `{${SurveyProperty.NAME}} {${SurveyProperty.DESCRIPTION}}`,
  });

  whatsapp: ServiceData = new ServiceData({
    name: 'whatsapp',
    shareTitle: '',
    shareBody: `{${SurveyProperty.NAME}} {${SurveyProperty.DESCRIPTION}}`,
  });

  google: ServiceData = new ServiceData({
    name: 'google',
  });

  linkedin: ServiceData = new ServiceData({
    name: 'linkedin',
  });

  constructor(data: Partial<SharingData> = {}) {
    mergeObjectWithProperties(this, data, [
      'outcomeSharing',
      'email',
      'facebook',
      'twitter',
      'whatsapp',
      'google',
      'linkedin',
    ]);
  }
}

export class ReleaseData {
  url: string = '';

  linkKey: string = '';

  version: string = '';

  online: boolean = false;

  poll: boolean;

  revision: number | null;

  emailLinks: { [emailKey: string]: { shareLink: { url: string }; name?: string } };
  shareLinks: ShareLinkData[] = [];

  // TODO: Add dictionary validation
  revisions: { [key: string]: string } = {};
  closeAt?: number;
  respondentsCount?: number;
}

export class ScoringData {
  type: Answers = Answers.NONE;

  value: string = '';
}

export class ShareLinkData {
  url: string = '';

  name: string = '';

  tags: string = '';

  linkKey: string = '';

  created?: number;

  default?: boolean;

  type?: ShareType;
}

export class FieldItemData {
  hint: string = '';

  value: string = '';
}

export class InterviewerData {
  maxQuestions: number = 5;
  goal: 'rootCause' | 'custom' = 'rootCause';
  rootQuestion: string = '';
  publicReference: boolean = false;
}

export class WhyFinderData {
  maxQuestions: number = 3;
  target: string = '';
  backgroundInfo: string = '';
  backgroundInfoUrl: string = '';
}

export interface FileUploadData {
  description?: string;
  maxSize?: number;
  allowedFormats?: string;
}

export class ChoiceItemData extends OrderData implements Translatable {
  content: string = '';

  comment?: boolean;

  imageStyle?: ImageStyles;

  hideContent?: boolean;

  customKey?: string;

  reportColor?: string;

  uuid?: string;

  constructor(data: Partial<ChoiceItemData> = {}) {
    super();

    if (data?.imageStyle) {
      this.imageStyle = new ImageStyles();
    }

    mergeObjectWithProperties(this, data, data?.imageStyle ? ['imageStyle'] : []);
  }
}

export class SliderLabelsData {
  min: string = '';
  max: string = '';
  axis: string = '';

  visible: boolean = true;

  smileys: string[] = [
    `assets/smileys/smiley-v2-1.png`,
    `assets/smileys/smiley-v2-2.png`,
    `assets/smileys/smiley-v2-3.png`,
    `assets/smileys/smiley-v2-4.png`,
    `assets/smileys/smiley-v2-5.png`,
  ];
}

export class SliderValuesData {
  min: number = 0;
  max: number = 100;
  step: number = 1;

  initial: number = 0.5;

  visible: boolean = true;
}

export interface CustomCtaData {
  action?: string;
  text?: string;
  url?: string;
}

export interface InfoTitleContentData {
  title?: string;
  content?: string;
  showTitle?: boolean;
}

export interface InfoMediaCtaData {
  image?: ImageStyles;
  videoEmbed?: VideoEmbedInfo;
  cta?: CustomCtaData;
}

export class OutcomeData
  extends OrderData
  implements ScoreableInterface, Translatable, WithLogic, InfoMediaCtaData, InfoTitleContentData
{
  type: Outcomes = Outcomes.OUTCOME;

  link: string = '';

  title: string = '';
  content: string = '';

  showTitle: boolean = true;

  scored: number | null = null;
  percent: number | null = null;

  /**
   * @deprecated use $key instead
   */
  scoring: string | null = null;

  image: ImageStyles = new ImageStyles();

  videoEmbed?: VideoEmbedInfo;

  cta?: CustomCtaData;

  showIf: { [questionKey: string]: LogicItemStatement };
  showWhen?: WhenLogicStatement[];
  operator?: LogicOperator;
  uuid?: string;

  constructor(data: Partial<OutcomeData> = {}) {
    super();
    mergeObjectWithProperties(this, data, ['image']);
  }
}

export enum TriggerType {
  Answer = 'answer',
}

export enum TriggerAction {
  Email = 'email',
}

export enum ChoiceLayout {
  Compact = 'compact',
  Standard = 'standard',
  Wide = 'wide',
}

export class TriggerData extends OrderData implements WithLogic {
  title: string;
  type: TriggerType;
  action: TriggerAction;
  actionLink: string;

  showIf: { [questionKey: string]: LogicItemStatement };
  showWhen?: WhenLogicStatement[];
  operator?: LogicOperator;
  uuid?: string;

  constructor(data: Partial<TriggerData> = {}) {
    super();
    mergeObjectWithProperties(this, data, []);
  }
}

export class QuestionData
  extends OrderData
  implements ScoreableInterface, Translatable, WithLogic, InfoTitleContentData, InfoMediaCtaData
{
  type: Questions = Questions.UNKNOWN;

  title: string = '';
  group: string = '';
  keywords: string = '';
  content: string = '';

  answer: string | null = null;

  /**
   * @deprecated use $key instead
   */
  scoring: string | null = null;

  visible: boolean = true;

  /**
   * @deprecated use required instead
   */
  mandatory: boolean = false;
  required: boolean = false;

  showTitle: boolean = true;
  showStatus: boolean = true;

  charsLimit: number = 0;
  choiceLimit: number = 1;

  sliderAnswer: boolean = true;
  sliderSmileys: boolean = false;

  inputField: FieldItemData = new FieldItemData();

  interviewer?: InterviewerData = new InterviewerData();
  whyFinder?: WhyFinderData = new WhyFinderData();

  image: ImageStyles = new ImageStyles();

  videoEmbed?: VideoEmbedInfo;

  cta?: CustomCtaData;

  fileUpload: FileUploadData = {};

  choiceList: ChoiceItemData[] = null;

  choiceLayout: ChoiceLayout = ChoiceLayout.Standard;

  choiceZoom?: boolean;

  choiceShuffle?: boolean;

  sliderLabels: SliderLabelsData = new SliderLabelsData();
  sliderValues: SliderValuesData = new SliderValuesData();

  sliderLabelsX: SliderLabelsData = new SliderLabelsData();
  sliderLabelsY: SliderLabelsData = new SliderLabelsData();

  sliderValuesX: SliderValuesData = new SliderValuesData();
  sliderValuesY: SliderValuesData = new SliderValuesData();

  showIf: { [questionKey: string]: LogicItemStatement };
  showWhen?: WhenLogicStatement[];
  operator?: LogicOperator;
  $repondents?: number;
  studioSource?: string;
  languages?: string[];

  constructor(data: Partial<QuestionData> = {}) {
    super();
    mergeObjectWithProperties(this, data, [
      'image',
      'inputField',
      'interviewer',
      'whyFinder',
      'sliderLabels',
      'sliderValues',
      'sliderLabelsX',
      'sliderLabelsY',
      'sliderValuesX',
      'sliderValuesY',
    ]);
  }
}

export type BuilderData = QuestionData | OutcomeData | TriggerData;
export type SurveyScoring = { [outcomeKey: string]: { [questionKey: string]: ScoringData } };

export const enum QuestionActionType {
  Delete,
  MoveUp,
  MoveDown,
  DeleteGroup,
  Editing,
}

export interface QuestionActionData {
  question: QuestionData;
  attached: BuilderData[];
  action: QuestionActionType;
  confirmAction?: any;
}

export interface FolderModel {
  $key?: string;
  $surveyCount?: number;
  $owner?: IdentityData;
  name: string;
  color: string;
  surveys: {
    [surveyKey: string]: Rights;
  };
  createdBy: string;
}

export const folderColors: string[] = [
  '#0083FF',
  '#0EABAD',
  '#7715E3',
  '#CF1196',
  '#18B53A',
  '#EF5D7D',
  '#FEE119',
  '#9A2CDE',
  '#93A0AB',
  '#1143CF',
  '#6FB400',
  '#B39005',
];

export type SurveySortParam = 'recent' | 'alphabetical' | 'respondents';

export interface SurveySearchParameters {
  query?: string;
  colors?: string[];
  active: boolean;
  inFolders: boolean;
  showAll: boolean;
  scope: 'org' | 'user';
  sort: SurveySortParam;
  view: 'surveys' | 'folders';
}

export interface SurveySearchPrefs {
  foldersSort: SurveySortParam;
  surveysSort: SurveySortParam;
  view: 'surveys' | 'folders';
}
