import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@env/environment';
import { Action, createSelector, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { StreamAction } from '@shared/decorators/stream-action.decorator';
import { Commands } from '@shared/enums/commands.enum';
import { Constants } from '@shared/enums/constants.enum';
import { Rights } from '@shared/enums/rights.enum';
import {
  EmailSenderData,
  FeedbackSettings,
  InviteData,
  TeamAdminSettings,
  TeamData,
  TeamParentSettings,
  TeamType,
  UserData,
  UserDiscoveredQuestion,
} from '@shared/models/account.model';
import { BetaFeatures, TeamFeature } from '@shared/models/features.model';
import { IdentityData } from '@shared/models/prefs.model';
import { shareRef } from '@shared/operators/share-ref.operator';
import { AccountManager } from '@shared/services/account-manager.service';
import { CloudFunctions } from '@shared/services/cloud-functions.service';
import { RightsManager } from '@shared/services/rights-manager.service';
import { SourceTypeService } from '@shared/services/source-type.service';
import {
  AcceptTeamInvite,
  AuthDoneFetchAccountData,
  CreateTeam,
  GetTeam,
  GetUser,
  GetUserInvites,
  JoinTeam,
  LeaveTeam,
  RejectTeamInvite,
  SetTeamColor,
  SetTeamLogo,
  SetTeamName,
  SetVisitorId,
  SwitchTeam,
  ToggleAdminTools,
  UpdateAllFeatures,
  UpdateTeamFeature,
  UpdateTeams,
  VerifyUser,
} from '@shared/states/account.actions';
import { ACCOUNT_STATE_TOKEN, AccountStateModel, TeamMembersCount } from '@shared/states/account.models';
import { SignOutWithRedirect, SignUpAuthenticated } from '@shared/states/auth.actions';
import { AuthState, AuthStateModel } from '@shared/states/auth.state';
import { PREFS_STATE_TOKEN, PrefsStateModel } from '@shared/states/prefs-state.models';
import { getPrefLanguage } from '@shared/states/prefs-state.utilities';
import { RouterState } from '@shared/states/router.state';
import { assertArray } from '@shared/utilities/array.utilities';
import { pickBy } from '@shared/utilities/object.utilities';
import {
  isChild,
  isParent,
  parseDefaultAdminSettings,
  teamColor,
  teamLogo,
  teamName,
} from '@shared/utilities/team.utilities';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import hasRights = Rights.hasRights;

@Injectable()
@State<AccountStateModel>({
  name: ACCOUNT_STATE_TOKEN,
  defaults: {
    team: null,
    user: null,
    visitorId: null,
  },
})
export class AccountState implements NgxsOnInit {
  @LocalStorage('report-team') static privateReportTeamKey: string | undefined;

  constructor(
    private store: Store,
    private am: AccountManager,
    private st: SourceTypeService,
    private cf: CloudFunctions,
    private rm: RightsManager,
    readonly http: HttpClient,
  ) {}

  ngxsOnInit(): void {
    this.st.registerSourceType('team', {
      action: GetTeam,
      storeSelector: () => AccountState.team,
      defaultKey: () => AccountState.teamKey,
      obs: {},
    });
  }

  /** SELECTORS */

  @Selector([AuthState])
  static isAccountOk({ team, user }: AccountStateModel, { userInfo }: AuthStateModel) {
    const isAnonymous = Boolean(userInfo?.isAnonymous);

    const isInfoLoaded = !!userInfo;
    const isDataLoaded = !!team && !!user;

    return isAnonymous ? isInfoLoaded : isDataLoaded;
  }

  @Selector()
  static team(state: AccountStateModel): TeamData {
    return state.team;
  }

  @Selector()
  static parent(state: AccountStateModel): TeamData {
    return state.parent || null;
  }

  @Selector()
  static parentFeedback(state: AccountStateModel): FeedbackSettings[] {
    const { feedback } = state.parent?.parentSettings || {};
    return Object.entries(feedback || {}).map(([key, value]) => ({ ...value, $key: key }));
  }

  @Selector([AccountState.team])
  static teamName(_, team: TeamData): string {
    return teamName(team);
  }

  @Selector([AccountState.team])
  static adminSettings(_, team: TeamData): TeamAdminSettings {
    return team?.adminSettings || parseDefaultAdminSettings({});
  }

  @Selector([AccountState.adminSettings])
  static disableBetaFeatures(_, adminSettings: TeamAdminSettings): boolean {
    return !!adminSettings?.disableBetaFeatures;
  }

  @Selector([AccountState.team, AccountState.adminSettings])
  static parentSettings(_, team: TeamData, adminSettings: TeamAdminSettings): TeamParentSettings {
    return ([TeamType.Parent, TeamType.Child].includes(adminSettings?.type) && team?.parentSettings) || {};
  }

  @Selector([AccountState.adminSettings, AccountState.parent])
  static parentAdminSettings(_, adminSettings: TeamAdminSettings, parent: TeamData): TeamAdminSettings | undefined {
    return ([TeamType.Child].includes(adminSettings?.type) && parent?.adminSettings) || undefined;
  }

  @Selector([AccountState.parentAdminSettings])
  static useChildQuota(_, parentAdminSettings: TeamAdminSettings): boolean {
    return !!parentAdminSettings?.useChildQuota;
  }

  @Selector([AccountState.parentAdminSettings])
  static childBetaFeatures(_, parentAdminSettings: TeamAdminSettings): boolean {
    return !!parentAdminSettings?.childBetaFeatures;
  }

  @Selector([AccountState.parentAdminSettings])
  static autoEnableChildBetaFeatures(_, parentAdminSettings: TeamAdminSettings): boolean {
    return !!parentAdminSettings?.autoEnableChildBetaFeatures;
  }

  @Selector([AccountState.parentAdminSettings])
  static parentBenchmarkDataEnabled(_, parentAdminSettings: TeamAdminSettings): boolean {
    return !!parentAdminSettings?.features?.[TeamFeature.BENCHMARK_DATA_FOR_CHILDREN];
  }

  @Selector([AccountState.features])
  static benchmarkDataEnabled(_, features: Record<TeamFeature, boolean>): boolean {
    return features?.[TeamFeature.BENCHMARK_DATA] === true;
  }

  @Selector([AccountState.isChild, AccountState.isTeamAdmin, AccountState.parentSettings])
  static canInvite(_, child: boolean, admin: boolean, parentSettings: TeamParentSettings): boolean {
    return admin && (!child || !parentSettings?.disableUserInvite);
  }

  @Selector([AccountState.parentSettings, PREFS_STATE_TOKEN, RouterState.queryParams])
  static gdprLink(_, parentSettings: TeamParentSettings, prefsState: PrefsStateModel, params: Params): string {
    const language = getPrefLanguage(prefsState, params);

    return parentSettings?.gdprLinks?.[language] || $localize`https://zef.fi/data-processing-addendum/`;
  }

  @Selector([AccountState.adminSettings])
  static isParent(_, { type }: TeamAdminSettings): boolean {
    return isParent(type);
  }

  @Selector([AccountState.isParent, AccountState.isTeamAdmin])
  static isParentAdmin(_, isParentTeam: boolean, isTeamAdmin: boolean): boolean {
    return isParentTeam && isTeamAdmin;
  }

  @Selector([AccountState.adminSettings])
  static isChild(_, { type }: TeamAdminSettings): boolean {
    return isChild(type);
  }

  @Selector([AccountState.isParent, AccountState.isTeamAdmin, AuthState.isZefAdmin])
  static canAccessConsole(_, parent: boolean, teamAdmin: boolean, zefAdmin: boolean): boolean {
    return (parent && teamAdmin) || zefAdmin;
  }

  @Selector([AccountState.adminSettings])
  static extensions(_, { extensions }: TeamAdminSettings): string[] {
    return assertArray(extensions);
  }

  @Selector([AccountState.adminSettings])
  static features(_, { features }: TeamAdminSettings): Record<TeamFeature, boolean> {
    const all = Object.keys(TeamFeature).length === Object.keys(features || {}).length;
    return Object.values(TeamFeature).reduce(
      (dict, key) => {
        return { ...dict, [key]: features?.[key] || false };
      },
      { all } as any,
    );
  }

  @Selector([AccountState.features])
  static isExperimentalEnabled(_, features): boolean {
    return assertArray(features).some((f) => f.key === TeamFeature.ENABLE_EXPERIMENTAL && f.value === true);
  }

  static isFeatureEnabled(feature: TeamFeature = TeamFeature.ENABLE_EXPERIMENTAL) {
    return createSelector(
      [AccountState.features, AccountState.autoEnableChildBetaFeatures, AccountState.disableBetaFeatures],
      (features: Record<TeamFeature, boolean>, enablementFromParent: boolean, teamDisablement: boolean) => {
        const isBetaFeature = BetaFeatures.includes(feature);
        const isExperimentalEnabled = features?.[TeamFeature.ENABLE_EXPERIMENTAL] === true || !!enablementFromParent;
        const isAllFeaturesEnabled = features?.[TeamFeature.ALL_FEATURES] === true || !!enablementFromParent;

        const isRequestedEnabled = features?.[feature] === true || (isAllFeaturesEnabled && isBetaFeature);

        return isRequestedEnabled && ((isBetaFeature && isExperimentalEnabled) || !isBetaFeature) && !teamDisablement;
      },
    );
  }

  @Selector([AccountState.adminSettings, AccountState.parentSettings])
  static templateAccess({ team }: AccountStateModel, admin: TeamAdminSettings, parent: TeamParentSettings) {
    const canUseZeffiTemplates =
      admin.type === TeamType.Child
        ? !parent.onlyParentTemplates
        : admin.type === TeamType.Parent || admin.type === TeamType.Client;

    const canCreateEmptyTemplate =
      admin.type === TeamType.Child
        ? !parent.disableBlankTemplates
        : admin.type === TeamType.Parent || admin.type === TeamType.Client;

    const templateTeamKeys: string[] = [];

    if (canUseZeffiTemplates) {
      templateTeamKeys.push(environment.templatesTeamKey);
    }

    if (admin.type === TeamType.Child) {
      templateTeamKeys.push(admin.childOf);
    } else if (team) {
      templateTeamKeys.push(team.$key);
    }

    // console.warn({ canUseZeffiTemplates, templateTeamKeys });

    return { canUseZeffiTemplates, canCreateEmptyTemplate, templateTeamKeys };
  }

  @Selector()
  static activeTeamKey(state: AccountStateModel): string {
    return state.user?.team;
  }

  @Selector()
  static user(state: AccountStateModel): UserData {
    return state.user;
  }

  @Selector()
  static userDiscoveredQuestions(state: AccountStateModel): Record<string, UserDiscoveredQuestion> {
    return state.user?.discoveredQuestions || {};
  }

  @Selector([AuthState.isZefAdmin])
  static showZefAdminTools(state: AccountStateModel, isZefAdmin: boolean): boolean {
    return (isZefAdmin && !state.user?.hideAdminTools) || false;
  }

  @Selector()
  static visitorId({ visitorId }: AccountStateModel): string {
    return (visitorId || '').split('_')[0] || '';
  }

  @Selector([AccountState.teamKey, AccountState.userKey])
  static teamKeyUserKey(state: AccountStateModel, teamKey, userKey): [string, string] {
    return [teamKey, userKey];
  }

  @Selector()
  static surveyRights(state: AccountStateModel): { [surveyKey: string]: Rights } {
    const teamKey = state.user?.team;
    const rights = state.user?.surveys?.[teamKey] || {};
    return pickBy(rights, (val) => val > 0);
  }

  @Selector()
  static userSurveys(state: AccountStateModel): string[] {
    const surveyRights = AccountState.surveyRights(state);
    return Object.keys(surveyRights || {});
  }

  @Selector()
  static userAvatar(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identity: IdentityData = state.user?.identities?.[teamKey];

    return identity?.avatar;

    // TODO: Check provider data ??
    // return state.info && (state.info.photoURL || state.info.providerData.find(i => !!i.photoURL).photoURL);
  }

  @Selector()
  static identity(state: AccountStateModel): IdentityData {
    const teamKey = state.user?.team;
    return state.user?.identities?.[teamKey];
  }

  @Selector()
  static invites(state: AccountStateModel): InviteData[] {
    return state.user?.invites || [];
  }

  @Selector()
  static isValidIdentity(state: AccountStateModel): boolean {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || {};
    return 'email' in identityData && ('firstName' in identityData || 'lastName' in identityData);
  }

  @Selector()
  static canNameTeamOnSignup(state: AccountStateModel): boolean {
    return !state.team?.name;
  }

  @Selector([AuthState.userUid])
  static userKey(state: AccountStateModel, uid: string): string {
    return state.user?.$key || uid || null;
  }

  @Selector()
  static userTeams(state: AccountStateModel): string[] {
    return (
      (state.user?.teams &&
        Object.entries(state.user.teams)
          .filter(([, teamRights]) => teamRights > 0)
          .map(([teamKey]) => teamKey)) ||
      []
    );
  }

  @Selector()
  static userAdminTeams(state: AccountStateModel): string[] {
    return Object.entries(state.user?.teams || {})
      .filter(([, teamRights]) => hasRights(Rights.ADMIN, teamRights))
      .map(([teamKey]) => teamKey);
  }

  @Selector()
  static userEmail(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || ({} as IdentityData);
    return identityData.email;
  }

  @Selector()
  static userPhone(state: AccountStateModel): string {
    const teamKey = state.user?.team;
    const identityData = state.user?.identities?.[teamKey] || ({} as IdentityData);
    return identityData.phone;
  }

  @Selector()
  static teamKey(state: AccountStateModel): string {
    // Anonymous users don't have team data,
    // we use default team from user data
    return state.team?.$key || state.user?.team || AccountState.privateReportTeamKey || null;
  }

  @Selector()
  static teamLogo({ team }: AccountStateModel): string {
    return teamLogo(team);
  }

  @Selector()
  static teamUsers({ team }: AccountStateModel): Record<string, Rights> {
    return team?.users || {};
  }

  @Selector()
  static teamColor({ team }: AccountStateModel): string {
    return teamColor(team);
  }

  @Selector()
  static isChildTeam({ team }: AccountStateModel) {
    return isChild(team);
  }

  @Selector()
  static teamMembersCount({ team }: AccountStateModel): TeamMembersCount {
    const rights = Object.values(team?.users || {});

    const count: TeamMembersCount = {
      owner: rights.filter((right) => right === Rights.OWNER).length,
      admin: rights.filter((right) => right === Rights.ADMIN).length,
      member: rights.filter((right) => right < Rights.ADMIN && right !== Rights.NONE).length,
      total: rights.filter((right) => right !== Rights.NONE).length,
    };

    return count;
  }

  @Selector()
  static userRole(state: AccountStateModel): Rights {
    const teamKey = state.user?.team;
    return state?.user?.teams?.[teamKey] || 0;
  }

  @Selector([RouterState.routeParams])
  static surveyRight(state: AccountStateModel, params: Params): Rights {
    const teamKey = state.user?.team;
    const surveyKey = params.survey;

    return state.user?.surveys?.[teamKey][surveyKey];
  }

  @Selector([AccountState.userRole])
  static isTeamOwner(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.OWNER, teamRight);
  }

  @Selector([AccountState.userRole])
  static isTeamAdmin(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.ADMIN, teamRight);
  }

  @Selector([AccountState.userRole])
  static isTeamMember(state: AccountStateModel, teamRight: Rights) {
    return Rights.hasRights(Rights.EDIT, teamRight);
  }

  static inviteData(inviteKey: string) {
    return createSelector([AccountState], (state: AccountStateModel) =>
      (state.user?.invites || []).find((data) => data.$key === inviteKey),
    );
  }

  @Selector([AccountState.adminSettings])
  static emailSender(_, { emailSender }: TeamAdminSettings): EmailSenderData {
    return emailSender || { address: 'noreply', domain: 'zeffimail.com', forwardTo: '', readonly: false };
  }

  @Selector([AccountState.adminSettings])
  static previousEmailSender(_, { previousEmailSender }: TeamAdminSettings): Partial<EmailSenderData> | undefined {
    return previousEmailSender;
  }

  @Selector([AccountState.emailSender])
  static emailForwardTo(_, emailSender: EmailSenderData): string {
    return emailSender?.forwardTo || '';
  }

  @Selector([AccountState.emailSender])
  static emailSenderAddress(_, emailSender: EmailSenderData): string {
    const defaultEmail = 'noreply@zeffimail.com';

    if (emailSender) {
      const { address, domain } = emailSender;
      return `${address}@${domain}`;
    }

    return defaultEmail;
  }

  @Selector([AccountState.identity])
  static emailSenderName({ team }: AccountStateModel, identity: IdentityData) {
    const { firstName, lastName } = identity || ({} as IdentityData);
    return firstName && lastName ? `${firstName} ${lastName}` : team?.name || 'noreply';
  }

  @Selector([AccountState.adminSettings])
  static smsSenderName(_, { smsSender }: TeamAdminSettings) {
    return smsSender?.name || Constants.DEFAULT_SMS_SENDER_NAME;
  }

  /** ACTIONS */

  @StreamAction(GetUser)
  getUser(
    { patchState }: StateContext<AccountStateModel>,
    { userKey }: GetUser, // dispatched on auth token change
  ): Observable<AccountStateModel> {
    return !userKey
      ? of(null)
      : this.am.loadUser(userKey).pipe(
          map((user: UserData) => patchState({ user })),
          shareRef(),
        );
  }

  @StreamAction(GetTeam)
  getTeam(
    { getState, patchState }: StateContext<AccountStateModel>,
    { teamKey }: GetTeam,
  ): Observable<AccountStateModel> {
    return !teamKey
      ? of(null)
      : this.am.loadTeam(teamKey).pipe(
          map((newTeamData: TeamData) => {
            const oldTeamData = getState().team;
            // Keep old team data when "$key" is the only property
            // It is used during custom auth for private signin
            const isTeamFromToken = Object.keys(oldTeamData || {}).length === 1 && !!oldTeamData.$key;
            const team = newTeamData ? newTeamData : isTeamFromToken ? oldTeamData : null;

            if (oldTeamData?.$key !== team?.$key) {
              console.log('Team data', team);
            }

            // filter removed users
            const users = pickBy(team?.users, (value) => value !== 0);

            return { team, users };
          }),
          switchMap(({ team, users }) =>
            !isChild(team)
              ? of({ team, users, parent: null })
              : this.am
                  .loadTeam(team.adminSettings?.childOf)
                  .pipe(map((parent: TeamData) => ({ team, users, parent }))),
          ),
          map(({ team, users, parent }) => patchState({ team: { ...team, users }, parent })),
        );
  }

  @Action(JoinTeam)
  joinTeam(ctx: StateContext<AccountStateModel>, { inviteKey }: JoinTeam) {
    return this.cf.postOnce(Commands.JoinTeam, inviteKey).pipe(catchError(() => of(null)));
  }

  @Action(CreateTeam)
  createTeam(ctx: StateContext<AccountStateModel>, { parentTeam }: CreateTeam) {
    return this.cf.postOnce(Commands.CreateTeam, `-/${parentTeam}`).pipe(catchError(() => of(null)));
  }

  @Action(UpdateTeams)
  updateTeams() {
    return this.cf.putOnce(Commands.CreateTeam).pipe(
      catchError(() => {
        sessionStorage.setItem('login-error', 'SSO post login error');

        return of(null);
      }),
    );
  }

  @Action(SetTeamName)
  setTeamName(ctx: StateContext<AccountStateModel>, { name }: SetTeamName) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.am.updateTeam(teamKey, { name });
  }

  @Action(SetTeamLogo)
  setTeamLogo(ctx: StateContext<AccountStateModel>, { logo }: SetTeamLogo) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.am.updateTeam(teamKey, { logo });
  }

  @Action(SetTeamColor)
  setTeamColor(ctx: StateContext<AccountStateModel>, { color }: SetTeamColor) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    this.am.updateTeam(teamKey, { color });
  }

  @Action(SwitchTeam)
  switchTeam(ctx: StateContext<AccountStateModel>, { teamKey }: SwitchTeam) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    return this.am.switchTeam(userKey, teamKey).pipe(map(() => window.location.replace('/')));
  }

  @Action(LeaveTeam)
  leaveTeam(ctx: StateContext<AccountStateModel>, { teamKey, userKey }: LeaveTeam) {
    return this.am.defaultTeamKey(userKey).pipe(
      filter((defaultTeamKey) => defaultTeamKey !== teamKey),
      tap(() => this.rm.setTeamRights(userKey, Rights.NONE, teamKey)),
      switchMap((defaultTeamKey) => this.am.switchTeam(userKey, defaultTeamKey)),
      map(() => window.location.replace('/')),
    );
  }

  @Action(AcceptTeamInvite)
  acceptTeamInvite({ dispatch }: StateContext<AccountStateModel>, { teamKey, inviteKey }: AcceptTeamInvite) {
    return this.am.acceptTeamInvite(teamKey, inviteKey).pipe(switchMap(() => dispatch(new GetUserInvites())));
  }

  @Action(RejectTeamInvite)
  rejectTeamInvite(ctx: StateContext<AccountStateModel>, { inviteKey }: RejectTeamInvite) {
    return this.am.rejectTeamInvite(inviteKey);
  }

  @Action(VerifyUser)
  verifyUser(ctx: StateContext<AccountStateModel>, { inviteKey }: VerifyUser) {
    return this.cf.postOnce(Commands.VerifyUser, inviteKey).pipe(catchError(() => of(null)));
  }

  @StreamAction(GetUserInvites)
  getUserInvites({ setState, getState }: StateContext<AccountStateModel>) {
    return this.am.listUserInvites().pipe(
      map((invites) => {
        if (getState().user) {
          setState(patch({ user: patch({ invites }) }));
        }
      }),
    );
  }

  @Action(SetVisitorId)
  setVisitorId({ patchState }: StateContext<AccountStateModel>, { visitorId }: SetVisitorId) {
    patchState({ visitorId });
  }

  @Action([SignOutWithRedirect, SwitchTeam])
  resetState({ patchState }: StateContext<AccountStateModel>) {
    patchState({ team: null });
  }

  @Action(UpdateTeamFeature)
  updateTeamFeature(_, { feature, value }: UpdateTeamFeature) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.am.updateTeamFeature(teamKey, feature, value).pipe(catchError(() => of(null)));
  }

  @Action(ToggleAdminTools)
  toggleAdminTools(_, { value }: ToggleAdminTools) {
    const userKey = this.store.selectSnapshot(AccountState.userKey);

    return this.am.toggleAdminTools(userKey, value).pipe(catchError(() => of(null)));
  }

  @Action(UpdateAllFeatures)
  updateAllFeatures(_, { data }: UpdateAllFeatures) {
    const teamKey = this.store.selectSnapshot(AccountState.teamKey);

    return this.am.updateTeamFeatures(teamKey, data).pipe(catchError(() => of(null)));
  }

  @Action(AuthDoneFetchAccountData)
  authDoneFetch({ dispatch }: StateContext<AccountStateModel>, { userKey }: AuthDoneFetchAccountData): Observable<any> {
    console.log('Auth done fetch', userKey);

    return dispatch(new GetUser(userKey)).pipe(
      switchMap(() => {
        const user = this.store.selectSnapshot(AccountState.user);
        const authClaims = this.store.selectSnapshot(AuthState.authClaims);

        const hasIdentity = Boolean(user);
        const hasActiveTeam = Boolean(user?.team);

        const authConfig = authClaims?.ory || authClaims?.firebase;
        const isSSOProvider =
          authConfig.sign_in_provider?.startsWith('saml.') || authConfig.sign_in_provider?.startsWith('oidc.');
        const isGoogleProvider = authClaims?.firebase?.sign_in_provider === 'google.com';
        const isMicrosoftProvider = authClaims?.firebase?.sign_in_provider === 'microsoft.com';

        const hasRightsToTeam = Object.values(user?.teams || {}).some((teamRight) => teamRight > 0);

        const logData = {
          hasIdentity,
          hasActiveTeam,
          hasRightsToTeam,
          isSSOProvider,
          isGoogleProvider,
          isMicrosoftProvider,
        };

        console.log('User data loaded', logData);

        if (hasActiveTeam) {
          return dispatch([new GetTeam(user?.team), new GetUserInvites()]);
        } else if ((isGoogleProvider || isMicrosoftProvider) && !hasIdentity) {
          return dispatch(new SignUpAuthenticated());
        } else if (isSSOProvider && !hasRightsToTeam) {
          return dispatch(new SignOutWithRedirect(true));
        } else {
          return of(null);
        }
      }),
    );
  }
}
